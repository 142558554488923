import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["name"]

    connect() {
        console.log("NameWrapperController connected")
        this.adjustName()
        this.resizeHandler = this.handleResize.bind(this)
        window.addEventListener('resize', this.resizeHandler)
    }

    disconnect() {
        window.removeEventListener('resize', this.resizeHandler)
    }

    handleResize() {
        this.adjustName()
    }

    adjustName() {
        if (!this.originalName) {
            this.originalName = this.nameTarget.textContent.trim()
        }
        console.log(`Adjusting name: "${this.originalName}"`)

        const fullName = this.originalName
        const steps = [
            this.noAdjustment,
            this.abbreviateMiddleNames,
            this.removeMiddleNames,
            this.abbreviateLastName,
            this.truncateWithEllipsis
        ]

        for (const step of steps) {
            const adjustedName = step.call(this, fullName)
            this.nameTarget.textContent = adjustedName
            console.log(`Trying adjusted name: "${adjustedName}"`)
            if (!this.isWrapping()) {
                console.log(`Adjusted name fits: "${adjustedName}"`)
                return
            }
        }
        console.log(`Using most aggressive truncation as fallback for: "${this.originalName}"`)
    }

    noAdjustment(name) {
        return name
    }

    isWrapping() {
        const nameRect = this.nameTarget.getBoundingClientRect()
        const parentRect = this.element.getBoundingClientRect()
        const threshold = 0.95 // 95% of parent width
        const buffer = 20 // 20px buffer
        const isWrapping = nameRect.width >= (parentRect.width - buffer) * threshold
        console.log(`Wrapping check for "${this.nameTarget.textContent}": Name width: ${nameRect.width}, Parent width: ${parentRect.width - buffer}, Threshold width: ${(parentRect.width - buffer) * threshold}, Is wrapping: ${isWrapping}`)
        return isWrapping
    }

    abbreviateMiddleNames(name) {
        const parts = name.split(' ')
        if (parts.length > 2) {
            return parts.map((part, index) => {
                if (index > 0 && index < parts.length - 1) {
                    return part.charAt(0) + '.'
                }
                return part
            }).join(' ')
        }
        return name
    }

    removeMiddleNames(name) {
        const parts = name.split(' ')
        if (parts.length > 2) {
            return [parts[0], parts[parts.length - 1]].join(' ')
        }
        return name
    }

    abbreviateLastName(name) {
        const parts = name.split(' ')
        if (parts.length > 1) {
            parts[parts.length - 1] = parts[parts.length - 1].charAt(0) + '.'
            return parts.join(' ')
        }
        return name
    }

    truncateWithEllipsis(name) {
        const maxLength = Math.floor(this.element.clientWidth / 10) // Approximate characters that fit
        const result = name.length > maxLength ? name.slice(0, maxLength - 1) + '…' : name
        console.log(`Truncating with ellipsis for: "${name}", Result: "${result}"`)
        return result
    }
}