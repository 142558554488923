// app/javascript/controllers/livekit_viewer_controller.js
import { Controller } from "@hotwired/stimulus"
import { Room, RoomEvent, Track } from 'livekit-client';

export default class extends Controller {
    static targets = [ "placeholder", "remoteVideoContainer" ]

    async connect() {
        console.log(`Connecting LiveKit viewer`);
        this.matchId = this.element.dataset.matchId;
        await this.connectAsViewer();
    }

    disconnect() {
        this.disconnectFromRoom();
    }

    async connectAsViewer() {
        try {
            const response = await fetch(`/matches/${this.matchId}/livekit_viewer_token`);
            const { token, wsURL, roomName } = await response.json();

            this.room = new Room();
            this.room.on(RoomEvent.TrackSubscribed, this.handleTrackSubscribed.bind(this));
            this.room.on(RoomEvent.TrackUnsubscribed, this.handleTrackUnsubscribed.bind(this));
            await this.room.connect(wsURL, token, { autoSubscribe: true });

            console.log('Test: connected to room', roomName);
        } catch (error) {
            console.error('Failed to connect to room:', error);
            throw error;
        }
    }

    handleTrackSubscribed(track, publication, participant) {
        console.log('Track subscribed:', track);
        if (track.kind === Track.Kind.Video) {
            const element = track.attach();
            element.setAttribute('data-participant-id', participant.identity);
            this.remoteVideoContainerTarget.appendChild(element);
            this.showVideo();
        } else if (track.kind === Track.Kind.Audio) {
            const element = track.attach();
            element.setAttribute('data-participant-id', participant.identity);
            document.body.appendChild(element);
        }
    }

    handleTrackUnsubscribed(track, publication, participant) {
        console.log('Track unsubscribed');
        if (track.kind === Track.Kind.Video) {
            this.removeVideoElement(participant.identity);
            if (this.remoteVideoContainerTarget.children.length === 0) {
                this.showPlaceholder();
            }
        }
    }

    disconnectFromRoom() {
        if (this.room) {
            this.room.disconnect();
            console.log('Disconnected from room');
        }

        this.remoteVideoContainerTarget.innerHTML = '';
        this.showPlaceholder();

        this.room = null;
    }

    showVideo() {
        this.placeholderTarget.classList.add('hidden');
        this.remoteVideoContainerTarget.classList.remove('hidden');
    }

    showPlaceholder() {
        this.placeholderTarget.classList.remove('hidden');
        this.remoteVideoContainerTarget.classList.add('hidden');
    }

    removeVideoElement(participantId) {
        const videoElement = this.remoteVideoContainerTarget.querySelector(`[data-participant-id="${participantId}"]`);
        if (videoElement) {
            videoElement.remove();
        }
    }
}