import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["time"]
  static values = {
    status: String,
    timeRemaining: Number
  }

  connect() {
    this.lastUpdateTime = performance.now()
    this.updateDisplay()
    this.startTimer()
  }

  startTimer() {
    this.stopTimer()
    if (this.statusValue === 'in_progress') {
      this.animationFrameId = requestAnimationFrame(this.tick.bind(this))
    }
  }

  stopTimer() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId)
    }
  }

  tick(timestamp) {
    const elapsed = (timestamp - this.lastUpdateTime)
    this.timeRemainingValue = Math.max(0, this.timeRemainingValue - elapsed)
    this.lastUpdateTime = timestamp
    this.updateDisplay()

    if (this.timeRemainingValue > 0 && this.statusValue === 'in_progress') {
      this.animationFrameId = requestAnimationFrame(this.tick.bind(this))
    } else {
      this.stopTimer()
      this.handleMatchEnd()
    }
  }

  updateDisplay() {
    var msRemaining = Math.round(this.timeRemainingValue)
    const minutes = Math.floor(msRemaining / 1000 / 60)
    const seconds = Math.floor(msRemaining / 1000 % 60)
    this.timeTarget.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  handleMatchEnd() {
    this.statusValue = 'ended'
    console.log("Match has ended")
    // You can add any additional end-of-match logic here
  }

  statusValueChanged() {
    if (this.statusValue === 'in_progress') {
      this.startTimer()
    } else {
      this.stopTimer()
    }
    this.updateDisplay()  // Update display when status changes
  }

  disconnect() {
    this.stopTimer()
  }
}