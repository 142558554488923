// In app/javascript/livekit_room.js
import { Room, RoomEvent, createLocalVideoTrack, createLocalAudioTrack, Track } from 'livekit-client';

let room;
let localParticipant;
let isPublishing = false;
let localVideoTrack;

export async function connectToMatchRoom(matchId) {
    try {
        const response = await fetch(`/matches/${matchId}/livekit_token`);
        const { token, wsURL, roomName } = await response.json();

        room = new Room();

        // Create local video track before connecting to the room
        localVideoTrack = await createLocalVideoTrack();

        // Attach local video to an element
        const videoElement = localVideoTrack.attach();
        document.getElementById('local-video-container').appendChild(videoElement);


        await room.connect(wsURL, token);
        console.log('connected to room', roomName);

        localParticipant = room.localParticipant;

        return room;
    } catch (error) {
        console.error('Failed to connect to room:', error);
        throw error;
    }
}


export async function togglePublishing() {
    if (!localParticipant) return;

    try {
        if (isPublishing) {
            await stopPublishing();
        } else {
            await startPublishing();
        }
        isPublishing = !isPublishing;
        return isPublishing;
    } catch (error) {
        console.error('Failed to toggle publishing:', error);
        throw error;
    }
}

// Modify the startPublishing function
async function startPublishing() {
    try {
        // Use the existing localVideoTrack
        const micTrack = await createLocalAudioTrack();

        await localParticipant.publishTrack(localVideoTrack);
        await localParticipant.publishTrack(micTrack);

        console.log('Started publishing local tracks');
    } catch (error) {
        console.error('Failed to publish local tracks:', error);
        throw error;
    }
}

async function stopPublishing() {
    if (!localParticipant) {
        console.error('Local participant is not initialized');
        return;
    }

    const publications = localParticipant.getTrackPublications();
    for (const publication of publications) {
        if (publication.track && (publication.track.kind === Track.Kind.Video || publication.track.kind === Track.Kind.Audio)) {
            await localParticipant.unpublishTrack(publication.track, false);
        }
    }

    console.log('Stopped publishing local tracks');
}

export function getPublishingState() {
    return isPublishing;
}

export function disconnectFromRoom() {
    if (room) {
        room.disconnect();
        console.log('Disconnected from room');
    }
}