import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["template", "container", "email"]

    addInvitation(event) {
        event.preventDefault()
        const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
        this.containerTarget.insertAdjacentHTML('beforeend', content)
    }

    removeInvitation(event) {
        event.preventDefault()
        const wrapper = event.target.closest('.invitation-fields')
        wrapper.remove()
    }
}