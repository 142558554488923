// app/javascript/controllers/match_form_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["rulesetSelection", "matchTypeSelection", "matchDetails", "matchTypeCards", "matchTypeInput"]
    static values = { rulesets: Array }

    selectRuleset(event) {
        const rulesetId = event.currentTarget.dataset.rulesetId
        const ruleset = this.rulesetsValue.find(r => r.id === parseInt(rulesetId))

        if (ruleset && ruleset.match_types) {
            this.matchTypeCardsTarget.innerHTML = ruleset.match_types.map(mt => `
        <div class="card bg-slate-700/50 shadow-xl cursor-pointer w-full" data-action="click->match-form#selectMatchType" data-match-type-id="${mt.id}">
          <div class="card-body">
            <h2 class="card-title text-slate-50">${mt.name}</h2>
            <p class="text-slate-50">${mt.description || ''}</p>
          </div>
        </div>
      `).join('')

            this.rulesetSelectionTarget.classList.add("hidden")
            this.matchTypeSelectionTarget.classList.remove("hidden")
        }
    }

    selectMatchType(event) {
        const matchTypeId = event.currentTarget.dataset.matchTypeId
        this.matchTypeInputTarget.value = matchTypeId

        this.matchTypeSelectionTarget.classList.add("hidden")
        this.matchDetailsTarget.classList.remove("hidden")
    }
}