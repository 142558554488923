// In app/javascript/controllers/livekit_room_controller.js
import { Controller } from "@hotwired/stimulus"
import { connectToMatchRoom, togglePublishing, disconnectFromRoom, getPublishingState } from '../livekit_room'

export default class extends Controller {
    static targets = [ "publishButton", "liveIndicator" ]

    async connect() {
        this.matchId = this.element.dataset.matchId;
        this.room = await connectToMatchRoom(this.matchId);
        const isPublishing = getPublishingState();
        this.updatePublishButtonText(isPublishing);
        this.publishButtonTarget.disabled = false;  // Enable button once connected
    }


    async togglePublishing() {
        const isCurrentlyPublishing = getPublishingState();

        if (!isCurrentlyPublishing) {
            try {
                const response = await fetch(`/matches/${this.matchId}/start_egress`, {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                        'Content-Type': 'application/json'
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to start egress');
                }

                await togglePublishing();
            } catch (error) {
                console.error('Error starting egress:', error);
                alert('Failed to start streaming. Please try again.');
                return;
            }
        } else {
            try {
                const response = await fetch(`/matches/${this.matchId}/stop_egress`, {
                    method: 'POST',
                    headers: {
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to stop egress');
                }

                await togglePublishing();
            } catch (error) {
                console.error('Error stopping egress:', error);
                alert('Failed to stop streaming. Please try again.');
                return;
            }
        }

        const isPublishing = getPublishingState();
        this.updatePublishButtonText(isPublishing);
        this.toggleLiveIndicator(isPublishing);
    }

    toggleLiveIndicator(isPublishing) {
        if (isPublishing) {
            this.liveIndicatorTarget.style.display = "flex"
        } else {
            this.liveIndicatorTarget.style.display = "none"
        }
    }

    updatePublishButtonText(isPublishing) {
        const button = this.publishButtonTarget
        button.textContent = isPublishing ? "Stop Stream" : "Start Stream"
        button.classList.toggle('btn-primary', !isPublishing)
        button.classList.toggle('btn-danger', isPublishing)
    }

    disconnect() {
        disconnectFromRoom();
    }
}