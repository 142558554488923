import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["rulesetSelection", "matchTypeSelection", "bracketTypeSelection", "bracketName", "matchTypeCards", "matchTypeInput", "bracketTypeInput"]
    static values = { rulesets: Array }

    selectRuleset(event) {
        const rulesetId = event.currentTarget.dataset.rulesetId
        const ruleset = this.rulesetsValue.find(r => r.id === parseInt(rulesetId))

        if (ruleset && ruleset.match_types) {
            this.matchTypeCardsTarget.innerHTML = ruleset.match_types.map(mt => `
      <div class="card bg-slate-700/50 shadow-xl cursor-pointer w-full" data-action="click->bracket-form#selectMatchType" data-match-type-id="${mt.id}">
        <div class="card-body">
          <h2 class="card-title text-slate-50">${mt.name}</h2>
          <p class="text-slate-50">${mt.description || ''}</p>
        </div>
      </div>
    `).join('')

            this.rulesetSelectionTarget.classList.add("hidden")
            this.matchTypeSelectionTarget.classList.remove("hidden")
        } else {
            console.error("Failed to find match types for ruleset:", rulesetId)
        }
    }

    selectMatchType(event) {
        const matchTypeId = event.currentTarget.dataset.matchTypeId
        this.matchTypeInputTarget.value = matchTypeId

        this.matchTypeSelectionTarget.classList.add("hidden")
        this.bracketTypeSelectionTarget.classList.remove("hidden")
    }

    selectBracketType(event) {
        const bracketType = event.currentTarget.dataset.bracketType
        this.bracketTypeInputTarget.value = bracketType

        this.bracketTypeSelectionTarget.classList.add("hidden")
        this.bracketNameTarget.classList.remove("hidden")
    }
}