import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["assignmentSection", "assignmentRadio", "inviteEmailField"]
    static values = { athleteAssigned: Boolean }

    connect() {
        this.updateVisibility()
    }

    updateAssignmentVisibility() {
        this.updateVisibility()
    }

    updateVisibility() {
        const selectedValue = this.assignmentRadioTarget.value
        this.assignmentSectionTarget.classList.toggle("hidden", this.athleteAssignedValue)
        this.inviteEmailFieldTarget.classList.toggle("hidden", selectedValue !== "invite")
    }
}